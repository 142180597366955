import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { charityTheme } from 'common/theme/charity';
import { ResetCSS } from 'common/assets/css/style';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/Charity/Navbar';
import DrawerSection from 'containers/Charity/DrawerSection';
import Footer from 'containers/Charity/Footer';

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from 'containers/Charity/charity.style';
import Seo from 'components/seo';

const Index = () => {
  return (
    <ThemeProvider theme={charityTheme}>
      <Fragment>
        <Seo
          title="Wildlife"
          description="Information about the different species of Wildlife residing in the meadows."
        />
        <ResetCSS />
        <GlobalStyle />
        {/* Start charity wrapper section */}
        <CharityWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <Navbar />
          </Sticky>
          <DrawerProvider>
            <DrawerSection />
          </DrawerProvider>
          <ContentWrapper style={{ padding: '2em' }}>
            <h1>Privacy Policy</h1>

            <p>Last Updated: 28th October 2021</p>

            <h2>1. INTRODUCTION</h2>

            <p>We are committed to safeguarding the privacy of lammaslands.com.</p>
            <p><strong>1.1</strong>	This policy applies where we are acting as a data controller with respect to the personal data of our website visitors and service users; in other words, where we determine the purposes and means of the processing of that personal data.</p>
            <p><strong>1.2</strong>	We use cookies on our website. Insofar as those cookies are not strictly necessary for the provision of our website and services, we will ask you to consent to our use of cookies when you first visit our website.</p>
            <p><strong>1.3</strong>	Our website incorporates privacy controls which affect how we will process your personal data. By using the privacy controls, you can specify whether you would like to receive direct marketing communications and limit the publication of your information. You can access the privacy controls via the cookie banner.</p>.
            <p><strong>1.4</strong>	In this policy, "we", "us" and "our" refer to the Lammas Lands Preservation Group.</p>

            <h2>2. HOW WE USE YOUR PERSONAL DATA</h2>

            <p><strong>2.1</strong> We do not process any personal data unless mentioned in the following section</p>

            <p><strong>2.2</strong> If agreed to via the cookie banner, we do collect anonymised user statistics ("usage data") via Google Analytics. The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your visits.</p>

            <p>
              <strong>2.3</strong> We may process information relating to our customer relationships, including customer contact information ("customer relationship data").
              The customer relationship data may include your name, your contact details, and information contained in communications between us and you.
            </p>

            <p>
              <strong>2.4</strong> We may process information contained in or relating to any communication that you send to us ("correspondence data"). The correspondence data
              may include the communication content and metadata associated with the communication. The correspondence data may be processed for the purposes
              of communicating with you and record-keeping. The legal basis for this processing is your legitimate interests, namely the proper administration
              of our website and communications with our users.
            </p>

            <p>
              <strong>2.5</strong> We may process any of your personal data identified in this policy where necessary for the exercise or defence of legal claims,
              whether in court proceedings or in an administrative or out-of-court procedure. The legal basis for this processing is our legitimate interests,
              namely the protection and assertion of our legal rights, your legal rights and the legal rights of others.
            </p>

            <h2>3. PROVIDING YOUR PERSONAL DATA TO OTHERS</h2>

            <p><strong>3.1</strong> We will not provide your data unless anonymized to a third party. We may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise or defence of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</p>

            <h2>4. RETAINING AND DELETING PERSONAL DATA</h2>

            <p>This Section sets out our data retention policies and procedure, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</p>

            <p><strong>4.1</strong> Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</p>

            <p><strong>4.2</strong> We store your data in the United Kingdom. We will only retain your personal information for as long as we need it.</p>
            <p><strong>4.3</strong> We consider the nature, sensitivity and purpose we collected your data for when deciding when it’s time to delete it. We will in some cases keep your data for longer periods of time than necessary for the original purpose we collected it for if we need to do this to meet our legal or regulatory requirements.</p>

            <h2>5. WHAT ARE YOUR RIGHTS?</h2>

            <p><strong>5.1</strong> Access your personal data (also known as a “subject access request”)</p>
            <p><strong>5.2</strong> Correct incomplete or inaccurate data we hold about you</p>
            <p><strong>5.3</strong> Ask us to erase the personal data we hold about you</p>
            <p><strong>5.4</strong> Ask us to restrict our handling of your personal data</p>
            <p><strong>5.5</strong> Ask us to transfer your personal data to a third party</p>
            <p><strong>5.6</strong> Object to how we are using your personal data</p>
            <p><strong>5.7</strong> Withdraw your consent to us handling your personal data</p>

            <h2>6. COOKIES</h2>

            <p> For a more detailed explanation of our cookie policy, please see <a href="/cookies">Our cookie policy.</a></p>

            <h2>7. DATA SECURITY</h2>

            <p><strong>6.1 </strong> We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. If you would like to know more about the security measures in place, please contact us at privacy@lammaslands.com</p>

            <h2>8. QUESTIONS, COMMENTS OR MORE DETAILS</h2>

            <p>We’ve worked hard to create a notice that’s easy to read and clear. But if you feel that we have overlooked an important perspective or used language which you think we could improve, please let us know by email at privacy@felloh.org. Your feedback and suggestions on this notice are welcome.</p>

            <p>Similarly, if you have any concerns you have a right to make a complaint to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk).  We would, however, always appreciate the chance to deal with any concerns you have first so please do contact us in the first instance.</p>

            <h2>8. CHANGES TO THIS NOTICE</h2>

            <p>We may change or update this Notice periodically. When we do, we will post the revised Notice on this web page indicating when the Notice was “Last Updated.”`</p>

            <p>It is also important that the data we hold about you is accurate and correct.  Please keep us informed if your personal data changes during your relationship with us.</p>

          </ContentWrapper>
        </CharityWrapper>
        {/* End of charity wrapper section */}
      </Fragment>
    </ThemeProvider>
  );
};
export default Index;
